import React from 'react';

const BannerTwo = () => {
    
    return (
        <React.Fragment>

            {/* <!-- banner section start --> */}
            <div className="rs-banner style2 pt-330 pb-330 lg-pt-200 lg-pb-200">
                <div className="container">
                    <div className="banner-content">
                        <div className="bnr-subtitle">It Software, Web App & Mobile App</div>
                        <h1 className="bnr-title">Leading Web App & Mobile App</h1>
                        <h3 className="bnr-titlesmall">Development Company</h3>
                        <div className="btn-part"><a className="readon buy-now get-in" onClick={() => { document.getElementById("rs-about").scrollIntoView({behavior: 'smooth'}); } }>Tentang Kami</a></div>
                    </div>
                </div>
            </div>
            {/* <!-- banner section end --> */}

        </React.Fragment>
    );
}

export default BannerTwo;