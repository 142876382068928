import React, { useState, useEffect }  from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProject from '../../components/Project/SingleProject';

import axios from 'axios';

const Project = () => {
    const [project,setProject]=useState([]);
    useEffect(()=>{
        async function fetchData() {
            await axios
                .get('https://webapi.erlanggastudio.id/tampil_project.php')
                .then(res=>{
                    console.log(res)
                    setProject(res.data);
                })
                .catch(err => {
                    console.log(err);
                });
        }
        fetchData();
    },[]);

    const options = {
        items: 3,
        nav: true,
        dots: false,
        margin: 30,
        rewind: false,
        autoplay: false,
        stagePadding: 30,
        loop: true,
        autoWidth: true,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        center: false,
        responsive: {
            0: {
                stagePadding: 0,
                items: 1,
                nav: false,
            },
            768: {
                items: 2,
                stagePadding: 0,
                nav:true,
            },
            992: {
                items: 2,
                stagePadding: 0,
            },
            1200: {
                items: 3,
                stagePadding: 0,
            },
            1500: {
                items: 3,
                stagePadding: 0,
            }
        }
    };

	return(
        <React.Fragment>
            <div id="rs-portfolio" className="rs-project style2 bg1 pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg white-color"
                        subtitle="Portofolio Kami"
                        titleClass="title title2 white-color"
                        title="Protofolio Proyek Kami Yang Baru Diluncurkan Tersedia di Marketplace"
                    />
                    <OwlCarousel options={options} >
                    {project.map((row)=>(
                        <SingleProject 
                            itemClass="project-item"
                            projectImage={row.project_image}
                            Title={row.project_title}
                            Category={row.project_category} 
                            projectURL={row.project_URL}
                            categoryURL={row.project_categoryURL} />
                    ))}
                    </OwlCarousel>
                </div>
            </div>
        </React.Fragment>
	)
}

export default Project