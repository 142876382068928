import React from 'react';

const FooterBottom = () => {
    return (
        <div className="footer-bottom">
            <div className="container">
                <div className="row y-middle">
                    <div className="col-lg-12">
                        <div className="copyright md-text-center md-mb-10">
                            <p align="center">© 2022 All Rights Reserved. Developed By Erlangga Studio</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterBottom;