import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceFlip from '../../components/Service/SingleServiceFlip';

import serviceIcon1 from '../../assets/img/service/main-home/1.png';
import serviceIcon2 from '../../assets/img/service/main-home/2.png';
import serviceIcon7 from '../../assets/img/service/main-home/7.png';

import shapeImg from '../../assets/img/service/s2.png';

const ServiceFlip = () => {

    return (
        <div id="rs-service" className="rs-services main-home style2 pt-120 pb-94 md-pt-80 md-pb-60">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-46"
                    subtitleClass="sub-text style-bg"
                    subtitle="Layanan Kami"
                    titleClass="title"
                    title="Kami Menawarkan Semua Jenis Layanan Solusi IT"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon2}
                            serviceURL="https://webapi.erlanggastudio.id/service-doc/daftar_harga_pembuatan_website.pdf"
                            Title="Web Development"
                            TextDesc="Kami mengembangkan solusi pengembangan aplikasi website untuk menunjang kebutuhan bisnis Anda."
                            ButtonClass="readon view-more"
                            ButtonText="Info Lebih Lanjut"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon7}
                            serviceURL="https://webapi.erlanggastudio.id/service-doc/daftar_harga_pembuatan_mobile_apps.pdf"
                            Title="Mobile Development"
                            TextDesc="Kami mengembangkan solusi pengembangan aplikasi mobile untuk menunjang kebutuhan bisnis Anda."
                            ButtonClass="readon view-more"
                            ButtonText="Info Lebih Lanjut"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-26">
                        <SingleServiceFlip
                            itemClass="flip-box-inner"
                            serviceImage={serviceIcon1}
                            serviceURL="https://edumy.erlanggastudio.id"
                            Title="Pelatihan IT Profesional"
                            TextDesc="Kami menyediakan pelatihan dibidang IT khusunya dibidang pengembangan aplikasi web dan mobile."
                            ButtonClass="readon view-more"
                            ButtonText="Info Lebih Lanjut"
                        />
                    </div>
                </div>
            </div>
            <div className="shape-animation">
                <div className="shape-part">
                    <img className="dance" src={shapeImg} alt="images" />
                </div>
            </div>
        </div>
    );
}

export default ServiceFlip;