import React from 'react';

import SectionTitle from '../../components/Common/SectionTitle';

import aboutImg from '../../assets/img/about/about-3.png';
import effectImg1 from '../../assets/img/about/effect-1.png';
import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';

const About = () => {

    return (
        <div id="rs-about" className="rs-about gray-bg pt-120 pb-120 md-pt-80 md-pb-80">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 md-mb-30">
                        <div className="rs-animation-shape">
                            <div className="images">
                                <img src={aboutImg} alt="" />
                            </div>
                            <div className="middle-image2">
                                <img className="dance" src={effectImg1} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 pl-60 md-pl-14">
                        <div className="contact-wrap">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title mb-30"
                                subtitleClass="sub-text style-bg"
                                subtitle="Tentang Kami"
                                titleClass="title pb-36"
                                title="Kami Meningkatkan Kesuksesan Bisnis Dengan Teknologi"
                                descClass="desc pb-34"
                                description="Lebih dari 10 tahun bekerja di layanan TI mengembangkan perangkat lunak, aplikasi web dan aplikasi mobile untuk klien di seluruh Indonesia."
                                secondDescClass="margin-0 pb-16"
                                secondDescription="Kami siap memberikan layanan terbaik bagi klien kami mengenai layanan TI dalam mengembangkan perangkat lunak, aplikasi web, dan aplikasi mobile."
                            />
                            {/* Section Title End */}
                            <div className="btn-part">
                                <a className="readon learn-more" onClick={() => { document.getElementById("rs-contact").scrollIntoView({behavior: 'smooth'}); } }>Hubungi Kami</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shape-image">
                    <img className="top dance" src={effectImg2} alt="" />
                    <img className="bottom dance" src={effectImg3} alt="" />
                </div>
            </div>
        </div>
    );
}

export default About;