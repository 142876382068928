import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = (props) => {
    const {submitBtnClass } = props;
    const { register, handleSubmit } = useForm();
    const [token, setToken] = useState();
    const history=useHistory();
    const recaptchaRef = React.useRef();

    const OnChange = (value) => { 
        console.log("Captcha value:", value);
        setToken(value);
        document.getElementById("btn_submit").disabled = false;
    }

    // specifying verify callback function
    var verifyCallback = function (response) {
        this.setState({
            reCaptchaResponse: response
        });
    };

    useEffect(()=>{
        setToken(token) 
    },[token]);

    const OnSubmit = () => { 
        const data = {
            contact_name : document.getElementById("contact_name").value,
            contact_email : document.getElementById("contact_email").value,
            contact_mobilephone : document.getElementById("contact_mobilephone").value,
            contact_website : document.getElementById("contact_website").value,
            contact_message : document.getElementById("contact_message").value,
            contact_serverKey : "6LcBcl8hAAAAAEpI4AX9afCHGg8CmJGJe9rshbcP",  //private key obtained from google
            contact_tokenVal : token,
            contact_siteKey : "6LcBcl8hAAAAAHlX0qzIcyzCJYzP_qL7bR7bMSq4"
        };
        axios
            .post('https://webapi.erlanggastudio.id/contact.php',data)
            .then(res=>{
                console.log(res.data.pesan);
                history.push('/onepage-3');
                swal(res.data.title, res.data.text, res.data.icon);
            })
            .catch(err => {
                console.log(err);
            });
    }

    return (
        <form id="contact-form" onSubmit={handleSubmit(OnSubmit)}>
            <div className="row">
                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="contact_name" name="contact_name" placeholder="Nama" {...register('contact_name')} required />
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="contact_email" name="contact_email" placeholder="E-Mail" {...register('contact_email')} required />
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="contact_mobilephone" name="contact_mobilephone" placeholder="No Telepon" {...register('contact_mobilephone')} required/>
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="contact_website" name="contact_website"  placeholder="Website Anda" {...register('contact_website')} />
                </div>

                <div className="col-12 mb-30">
                    <textarea className="from-control" id="contact_message" name="contact_message" placeholder="Isi Pesan" {...register('contact_message')} required ></textarea>
                </div>

                <div className="col-md-6 mb-30">
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LcBcl8hAAAAAHlX0qzIcyzCJYzP_qL7bR7bMSq4"
                    verifyCallback={verifyCallback}
                    onChange={OnChange}
                />
                </div>
            </div>
            <div className="btn-part">
                <button className={submitBtnClass ? submitBtnClass : 'readon learn-more submit'} id="btn_submit" type="submit" disabled >Kirim</button>
            </div>
        </form>
    );

}

export default ContactForm;