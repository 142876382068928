import React from 'react';
import OnepageMenuItems from './OnepageMenuItems';

const RSOnepageMobileMenu = (props) => {
    const { item1, item2, item3, item4, item7, menuOpen } = props;
    return (
        <div className="container relative">
            <div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
                <div className="mobile-menu">
                    <ul className="nav-menu">
                        <OnepageMenuItems
                            menuItem1={item1 ? item1 : 'Beranda'}
                            menuItem2={item2 ? item2 : 'Tentang Kami'}
                            menuItem3={item3 ? item3 : 'Layanan'}
                            menuItem4={item4 ? item4 : 'Portofolio'}
                            menuItem7={item7 ? item7 : 'Kontak'}
                        />
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default RSOnepageMobileMenu;