import React from 'react';
import { Link } from 'react-router-dom';
import FooterBottom from './FooterBottom';

import footerLogo1 from '../../../assets/img/logo/logo.png';

const Footer = (props) => {
    const { footerLogo, footerClass } = props;
    return (
        <footer className={footerClass ? footerClass : 'rs-footer'}>
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-4">
                            <div className="footer-logo">
                                <Link to="/" ><img src={footerLogo ? footerLogo : footerLogo1} alt="Logo" /></Link>
                            </div>
                            <div className="textwidget pb-30">
                                <p align="justify"> Kami memberikan layanan Teknologi Informasi dalam pengembangan aplikasi web dan pengembangan aplikasi mobile. Memberikan pelayanan terbaik bagi solusi permasalahan Teknologi Informasi Anda.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 pl-44 md-pl-14 md-mb-30">
                            <h3 className="widget-title">Layanan Kami</h3>
                            <ul className="site-map">
                                <li><a href="https://webapi.erlanggastudio.id/service-doc/daftar_harga_pembuatan_website.pdf" target="_blank" >Web Development</a></li>
                                <li><a href="https://webapi.erlanggastudio.id/service-doc/daftar_harga_pembuatan_mobile_apps.pdf" target="_blank" >Mobile Application Development</a></li>
                                <li><a href="https://edumy.erlanggastudio.id" target="_blank" >Pelatihan IT Profesional</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 md-mb-30">
                            <h3 className="widget-title">Informasi Kontak</h3>
                            <ul className="address-widget">
                                <li>
                                    <i className="flaticon-location"></i>
                                    <div className="desc">BTN Balitsa Cikole M.10, Lembang - Bandung</div>
                                </li>
                                <li>
                                    <i className="flaticon-call"></i>
                                    <div className="desc">
                                        <Link to="#">+6281321397362</Link>
                                    </div>
                                </li>
                                <li>
                                    <i className="flaticon-email"></i>
                                    <div className="desc">
                                        <Link to="#">support@erlanggastudio.id</Link>
                                    </div>
                                </li>
                                <li>
                                    <i className="flaticon-clock"></i>
                                    <div className="desc">
                                        Jam Kerja : 10:00 - 18:00
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12">
                            <h3 className="widget-title">Sosial Media Kami</h3>
                            <ul className="footer-social md-mb-30">
                                <li>
                                    <a href="https://www.facebook.com/erlanggastudio" target="_blank"><i className="fa fa-facebook-f"></i></a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/ErlanggaStudio" target="_blank"><i className="fa fa-twitter" target="_blank"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/erlangga.studio" target="_blank"><i className="fa fa-instagram" target="_blank"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <FooterBottom />
        </footer>
    );
}

export default Footer;