import React from 'react';
import About from '../home-3/AboutSection';
import Project from '../home-3/ProjectSection';
import ServiceFlip from '../home-3/ServiceSectionFlip';
import CTA from '../../components/Common/CTA';
import BannerTwo from '../../components/Banner/BannerTwo';
import ScrollToTop from '../../components/Common/ScrollTop';
import OnepageContactPart from '../../components/Contact/OnepageContact';


const HomeThreeOnepageMain = () => {

	return (
		<React.Fragment>
			{/* banner-start */}
			<BannerTwo />
			{/* banner-start */}
			
			{/* About-area-start */}
			<About />
			{/* About-area-end */}

			{/* ServiceFlip-area-start */}
			<ServiceFlip /> 
			{/* ServiceFlip-area-end */}

			{/* project-area-start */}
			<Project />
			{/* project-area-end */}

			{/* Contact-area-start */}
			<OnepageContactPart 
				contactSectionClass="rs-contact gray-bg pt-120 md-pt-80"
			/>
			{/* Contact-area-end */}

			{/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-70 pb-70"
				ctaTitleClass="epx-title"
				ctaTitle="Kembangkan Bisnis Anda dan Bangun Situs Web atau Aplikasi Anda Bersama kami."
				ctaButtonClass="readon learn-more"
				ctaButtonLink="https://api.whatsapp.com/send?phone=+6281321397362&text=Send20%a20%quote"
				ctaButtonText="Hubungi Kami"
			/>
			{/* newsletter-area-end */}

			{/* scrolltop-start */}
			<ScrollToTop />
			{/* scrolltop-end */}
		</React.Fragment>
	);
}

export default HomeThreeOnepageMain;